import "./App.css";
import { ProfilePage } from "./components/ProfilePage";
import "bootstrap/dist/css/bootstrap.css";
import { auth, db, storage } from "./components/Firebase";

import { Verify } from "./components/Verify";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ProfilePage />} />
        <Route path="*" element={<ProfilePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
